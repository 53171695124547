<template>
  <div class="internal-header">
    <div class="internal-header__upper">
      <div class="internal-header__upper__wrapper margin-container">
        <img
          :src="require(`@/assets/svgs/user_placeholder.svg`)"
          class="user_image"
        >
        <div class="internal-header__upper__wrapper__inner">
          <div class="d-flex justify-content-between">
            <span class="name">
              {{ getUser.name }}
            </span>
            <div class="add-property-button">
              <BaseButton
                type="outlined"
                @click="$router.push({ name: 'rent-process' })"
              >
                <template slot>
                  <div class="add-property-button__inner">
                    <span class="mdi mdi-plus-circle-outline" />
                    <span>
                      {{ $t('components.internalHeader.addProperty') }}
                    </span>
                  </div>
                </template>
              </BaseButton>
            </div>
          </div>
          <div
            class="profile-button"
            @click="$router.push({ name: 'dashboard-profile' })"
          >
            <span class="mdi mdi-home-account" />
            <span>
              {{ $t('components.internalHeader.profile') }}
            </span>
          </div>
        </div>
        <div class="internal-header__upper__wrapper__inner-mobile">
          <span class="name">
            {{ getUser.name }}
          </span>
          <div
            class="profile-button"
            @click="$router.push({ name: 'dashboard-profile' })"
          >
            <span class="mdi mdi-home-account" />
            <span>
              {{ $t('components.internalHeader.profile') }}
            </span>
          </div>
          <div class="add-property-button">
            <BaseButton
              type="outlined"
              @click="$router.push({ name: 'rent-process' })"
            >
              <template slot>
                <div class="add-property-button__inner">
                  <span class="mdi mdi-plus-circle-outline" />
                  <span>
                    {{ $t('components.internalHeader.addProperty') }}
                  </span>
                </div>
              </template>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="internal-header__lower">
      <div
        class="category_button"
        :class="$route.name === 'dashboard-listings' ? 'active' : ''"
        @click="$router.push({ name: 'dashboard-listings' })"
      >
        <span class="mdi mdi-card-text-outline" />
        <span v-if="getBreakpoints.smAndUp">
          {{ $t('components.internalHeader.listings') }}
        </span>
      </div>
      <!-- TODO
      <div class="category_button">
        <span class="mdi mdi-text-box-edit-outline" />
        <span v-if="getBreakpoints.smAndUp">
          {{ $t('components.internalHeader.bookings') }}
        </span>
      </div>
      <div class="category_button">
        <span class="mdi mdi-bell-outline" />
        <span v-if="getBreakpoints.smAndUp">
          {{ $t('components.internalHeader.notifications') }}
        </span>
      </div>
      <div class="category_button">
        <span class="mdi mdi-message-text-outline" />
        <span v-if="getBreakpoints.smAndUp">
          {{ $t('components.internalHeader.messages') }}
        </span>
      </div>
      <div class="category_button">
        <span class="mdi mdi-calendar-month-outline" />
        <span v-if="getBreakpoints.smAndUp">
          {{ $t('components.internalHeader.calendar') }}
        </span>
      </div> -->
      <div
        class="category_button"
        :class="$route.name === 'dashboard-favorites' ? 'active' : ''"
        @click="$router.push({ name: 'dashboard-favorites' })"
      >
        <span class="mdi mdi-heart-outline" />
        <span v-if="getBreakpoints.smAndUp">
          {{ $t('components.internalHeader.favorites') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BaseButton } from 'ff-components-lib';

export default {
  name: 'InternalHeader',
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getUser']),
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.internal-header {
  width: 100%;

  &__upper {
    height: 260px;
    background: url(../../../assets/svgs/dashboard_header_background.svg),
      lightgray 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    &__wrapper {
      display: flex;
      gap: 20px;
      margin-bottom: 22px;

      .user_image {
        width: 138px;
        height: 138px;
        aspect-ratio: 1;
        border-radius: 50%;
        margin-bottom: -42px;
      }

      &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
      }

      &__inner-mobile {
        display: none;
      }

      .name {
        font-size: 32px;
        color: white;
        font-weight: 500;
        line-height: 1;
      }

      .add-property-button {
        ::v-deep .base-button {
          background: unset;
          border: 1px solid $tertiary;
          color: $tertiary;

          &:hover,
          &:active {
            background: unset;
          }
        }

        &__inner {
          display: flex;
          gap: 6px;
        }

        .mdi {
          font-size: 20px;
          height: 20px;
          line-height: 1;
          aspect-ratio: 1;
        }
      }

      .profile-button {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        width: fit-content;

        opacity: 0.5;
        color: $tertiary;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;

        .mdi {
          font-size: 20px;
          height: 20px;
          line-height: 1;
          aspect-ratio: 1;
        }
      }
    }
  }

  &__lower {
    height: 80px;
    background: white;
    display: flex;
    justify-content: center;
    gap: 48px;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }

    .category_button {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 100%;
      font-weight: 600;
      cursor: pointer;

      .mdi {
        font-size: 24px;
        height: 24px;
        line-height: 1;
        aspect-ratio: 1;
      }

      &.active {
        color: $primary;

        &::before {
          position: absolute;
          top: calc(100% - 2px);
          width: 100%;
          height: 2px;
          background: $primary none repeat scroll 0% 0%;
          display: inline-block;
          content: '';
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .internal-header {
    &__lower {
      justify-content: flex-start;
      padding: 0 30px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .internal-header {
    &__upper {
      height: auto;
      background-position: left;

      &__wrapper {
        padding-top: 94px;
        flex-direction: column;
        align-items: center;
        gap: 0;

        &__inner {
          display: none;
        }

        &__inner-mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .user_image {
          width: 80px;
          height: 80px;
          margin-bottom: 8px;
        }

        .name {
          font-size: 24px;
          margin-bottom: 4px;
        }

        .add-property-button {
          margin-top: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .internal-header {
    &__lower {
      height: 72px;
      justify-content: center;
      gap: 40px;
      padding: 0;

      .category_button {
        gap: 0;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .internal-header {
    &__lower {
      justify-content: space-around;
      gap: 0;
    }
  }
}
</style>
