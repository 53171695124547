<template>
  <div class="main-background">
    <Sidebar />
    <UserSidebar />
    <Header />
    <InternalHeader />
    <div class="margin-container mt-7">
      <Breadcrumb
        :breadcrumbs="getBreadcrumb"
        @click="handleBreadcrumbClick"
      />
    </div>
    <div class="internal-body">
      <slot name="body" />
    </div>
    <Footer />
    <SubFooter />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { Breadcrumb } from 'ff-components-lib';

import Sidebar from '@/components/layout/Sidebar';
import UserSidebar from '@/components/layout/UserSidebar';
import Header from '@/components/layout/Header';
import InternalHeader from '@/components/layout/internal/InternalHeader';
import Footer from '@/components/layout/Footer';
import SubFooter from '@/components/layout/SubFooter';

export default {
  name: 'InternalLayout',
  components: {
    Sidebar,
    UserSidebar,
    Header,
    InternalHeader,
    Breadcrumb,
    Footer,
    SubFooter,
  },
  computed: {
    ...mapGetters(['getBreadcrumb']),
  },
  methods: {
    handleBreadcrumbClick(index) {
      this.$router.push({
        path: this.getBreadcrumb[index].route,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-background {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $tertiary;

  .internal-body {
    margin-top: 48px;
    margin-bottom: 48px;
    min-height: calc(100vh - 837px);
  }
}
</style>
