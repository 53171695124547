<template>
  <InternalLayout>
    <template slot="body">
      <div class="profile-screen margin-container">
        <!-- TODO <Tabs
          name="user-profile-tabs"
          :tabs="$t('sections.user-profile.tabs')"
          @change="handleTabsChange"
        /> -->
        <div
          class="personal-info-section"
          v-if="section === 0"
        >
          <div class="d-flex justify-content-between">
            <span class="personal-info-section__subtitle">
              {{ $t('sections.user-profile.personalDetails') }}
            </span>
            <div
              class="edit-button"
              @click="edit = true"
              v-if="!edit"
            >
              <span class="mdi mdi-cog-outline" />
              <span>
                {{ $t('sections.listing-detail.editButton') }}
              </span>
            </div>
          </div>
          <div class="personal-info-section__main">
            <div
              class="personal-info-section__fields"
              v-if="!edit"
            >
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.name.value') }}
                </span>
                <span class="field__value">
                  {{ user.name }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.address.value') }}
                </span>
                <span class="field__value">
                  {{ user.address }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.city.value') }}
                </span>
                <span class="field__value">
                  {{ user.city }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.postalCode.value') }}
                </span>
                <span class="field__value">
                  {{ user.postalCode }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.district.value') }}
                </span>
                <span class="field__value">
                  {{ user.district }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.email.value') }}
                </span>
                <span class="field__value">
                  {{ user.email }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.phone.value') }}
                </span>
                <span class="field__value">
                  {{ formattedPhone }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.nif.value') }}
                </span>
                <span class="field__value">
                  {{ user.nif }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.birthdate.value') }}
                </span>
                <span class="field__value">
                  {{ user.birthdate }}
                </span>
              </div>
              <div class="field">
                <span class="field__label">
                  {{ $t('sections.user-profile.inputs.nationality.value') }}
                </span>
                <span class="field__value">
                  {{ user.nationality }}
                </span>
              </div>
            </div>
            <div
              class="personal-info-section__inputs"
              v-else
            >
              <div class="row">
                <div class="col-sm-7 col-12 mb-sm-0 mb-6">
                  <VerticalTitleInput
                    :title="$t('sections.user-profile.inputs.name.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.name.placeholder')
                    "
                    :value="user.name"
                    :valid="validName"
                    :error-label="$t('components.input.missingField')"
                    @input="(e) => handleInput('name', e)"
                  />
                </div>
                <div class="col-sm-5 col-12 mb-sm-0 mb-6">
                  <PhoneInput
                    name="phone-number"
                    :title="$t('sections.user-profile.inputs.phone.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.phone.placeholder')
                    "
                    :code-placeholder="
                      $t('sections.user-profile.inputs.countryCode.placeholder')
                    "
                    :items="$t('arrays.phoneCountryCodes')"
                    :value="user.phone"
                    :code-value="user.phoneCountryCode"
                    @input="(e) => handleInput('phone', e)"
                    @codeInput="(e) => handleInput('phoneCountryCode', e)"
                    :error-label="$t('components.input.invalidPhone')"
                  />
                </div>
              </div>
              <VerticalTitleInput
                :title="$t('sections.user-profile.inputs.address.value')"
                :placeholder="
                  $t('sections.user-profile.inputs.address.placeholder')
                "
                :value="user.address"
                @input="(e) => handleInput('address', e)"
              />
              <div class="row">
                <div class="col-md-6 col-12 mb-md-0 mb-6">
                  <VerticalTitleInput
                    :title="$t('sections.user-profile.inputs.city.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.city.placeholder')
                    "
                    :value="user.city"
                    @input="(e) => handleInput('city', e)"
                  />
                </div>
                <div class="col-sm-5 col-md-3 col-12 mb-sm-0 mb-6">
                  <VerticalTitleInput
                    :title="$t('sections.user-profile.inputs.postalCode.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.postalCode.placeholder')
                    "
                    :value="user.postalCode"
                    @input="(e) => handleInput('postalCode', e)"
                  />
                </div>
                <div class="col-sm-5 col-md-3 col-12">
                  <VerticalTitleInput
                    type="dropdown"
                    :title="$t('sections.user-profile.inputs.district.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.district.placeholder')
                    "
                    :items="$t('arrays.districts')"
                    :value="user.district"
                    @input="(e) => handleInput('district', e)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-12 mb-md-0 mb-6">
                  <VerticalTitleInput
                    :title="$t('sections.user-profile.inputs.nif.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.nif.placeholder')
                    "
                    :value="user.nif"
                    @input="(e) => handleInput('nif', e)"
                  />
                </div>
                <div class="col-md-4 col-12 mb-md-0 mb-6">
                  <VerticalTitleInput
                    :title="$t('sections.user-profile.inputs.birthdate.value')"
                    :placeholder="
                      $t('sections.user-profile.inputs.birthdate.placeholder')
                    "
                    :value="user.birthdate"
                    @input="(e) => handleInput('birthdate', e)"
                  />
                </div>
                <div class="col-md-4 col-12">
                  <VerticalTitleInput
                    :title="
                      $t('sections.user-profile.inputs.nationality.value')
                    "
                    :placeholder="
                      $t('sections.user-profile.inputs.nationality.placeholder')
                    "
                    :value="user.nationality"
                    @input="(e) => handleInput('nationality', e)"
                  />
                </div>
              </div>
              <div class="buttons">
                <BaseButton
                  large
                  type="outlined"
                  :label="$t('sections.user-profile.cancelButton')"
                  @click="cancel"
                />
                <BaseButton
                  large
                  :label="$t('sections.user-profile.saveButton')"
                  @click="editUser"
                />
              </div>
            </div>
            <!-- TODO <div class="personal-info-section__profile-completion">
              <div class="personal-info-section__profile-completion-inner" />
            </div> -->
          </div>
          <!-- TODO <span class="personal-info-section__subtitle">
            {{ $t('sections.user-profile.socialAccounts') }}
          </span> -->
        </div>
        <!-- TODO <div v-if="section === 1" /> -->
      </div>
    </template>
  </InternalLayout>
</template>
<script>
import { cloneDeep, merge } from 'lodash';
import { mapGetters } from 'vuex';
import {
  /* Tabs, */ VerticalTitleInput,
  PhoneInput,
  BaseButton,
} from 'ff-components-lib';
import { validateEmail } from '@/utils';

import InternalLayout from '@/components/layout/internal/InternalLayout';

export default {
  name: 'ProfileScreen',
  components: {
    InternalLayout,
    // Tabs,
    VerticalTitleInput,
    PhoneInput,
    BaseButton,
  },
  data() {
    return {
      section: 0,
      edit: false,
      user: {
        name: undefined,
        email: undefined,
        nif: undefined,
        birthdate: undefined,
        nationality: undefined,
        address: undefined,
        city: undefined,
        postalCode: undefined,
        district: undefined,
        phoneCountryCode: undefined,
        phone: undefined,
      },
      savedUser: {
        name: undefined,
        email: undefined,
        nif: undefined,
        birthdate: undefined,
        nationality: undefined,
        address: undefined,
        city: undefined,
        postalCode: undefined,
        district: undefined,
        phoneCountryCode: undefined,
        phone: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getUser']),
    formattedPhone() {
      return this.user.phone
        ? `${this.user.phoneCountryCode} ${this.user.phone}`
        : '';
    },
    validName() {
      return (
        this.user.name === undefined
        || (typeof this.user.name === 'string' && this.user.name.length > 0)
      );
    },
    validEmail() {
      if (this.user.email === undefined) {
        return true;
      }
      if (this.user.email && this.user.email.length > 0) {
        return validateEmail(this.user.email);
      }
      return false;
    },
    validFields() {
      return this.name && this.validName && this.email && this.validEmail;
    },
  },
  async created() {
    this.user = cloneDeep(merge(this.savedUser, this.getUser));
    this.savedUser = cloneDeep(this.user);
  },
  methods: {
    handleTabsChange(tab) {
      this.section = tab;
    },
    handleInput(field, value) {
      this.user[field] = value;
    },
    async editUser() {
      try {
        this.$store.dispatch('storeLoading', true);
        await this.$store.dispatch('updateUser', this.user);
        this.$store.dispatch('storeLoading', false);

        this.edit = false;
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    cancel() {
      this.edit = false;
      this.user = cloneDeep(this.savedUser);
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-screen {
  display: flex;
  flex-direction: column;
  gap: 74px;

  .personal-info-section {
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-right: 8%;
    margin-left: 8%;

    &__subtitle {
      color: $grey;
      font-weight: 500;
      line-height: 24px;
    }

    .edit-button {
      display: flex;
      align-items: center;
      gap: 6px;
      color: $grey;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;

      .mdi {
        font-size: 20px;
        height: 20px;
        line-height: 1;
        aspect-ratio: 1;
      }
    }

    &__main {
      display: flex;
      gap: 24px;

      .personal-info-section__fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        border-radius: 8px;
        background-color: white;

        .field {
          display: flex;
          align-items: center;
          gap: 16px;

          &__label {
            color: $grey;
            font-weight: 500;
            line-height: 24px;
          }

          &__value {
            font-weight: 600;
            line-height: 24px;
          }
        }
      }

      .personal-info-section__inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .buttons {
          display: flex;
          justify-content: center;
          gap: 16px;
          margin-top: 48px;
        }
      }

      .personal-info-section__profile-completion {
        width: 252px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 24px;
        border-radius: 24px;
        background-color: rgba(#6b6b75, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(#6b6b75, 0.1) inset;

        &-inner {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          padding: 24px;
          border-radius: 8px;
          background-color: white;
          box-shadow: 0px 4px 10px 0px rgba($primary, 0.1);
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .profile-screen {
    .personal-info-section {
      gap: 30px;
      margin-right: 2%;
      margin-left: 2%;
    }
  }
}
</style>
